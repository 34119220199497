import DealDto from '@modules/components/schedule/dto/deal.dto';
import TeacherDto from '@modules/components/schedule/dto/teacher.dto';
import StudentDto from '@shared/services/students/dto/student.dto';
import HistoryDto from '@modules/components/schedule/dto/history.dto';
import MaterialDto from '@modules/components/schedule/dto/material.dto';
import {
  LessonTeacherRates
} from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/enums/lesson-teacher-rates.enum';

export enum LessonType {
  FIRST = 'first',
  REGULAR = 'regular',
}

export enum LessonStatus {
  BOOKED = 'booked',
  RESERVED = 'reserved',
  CANCELED = 'canceled',
}

export enum LessonAction {
  MOVE = 'move',
  SKIP = 'skip',
  CONDUCT = 'conduct',
  EDIT_CONDUCTED_LESSON = 'edit_conducted_lesson',
  REMOVE_CONDUCTION = 'remove_conduction',
}

export enum LessonHomeworkCompletion {
  DONE = 'done',
  UNDONE = 'undone',
  NO_TASK = 'no_task',
  PARTIALLY_DONE = 'partially_done',
}

export interface LessonHistoryContext {
  action: LessonAction;
}

export default interface LessonDto {
  id: string;
  order: number;
  date: string;
  timeSlot: number[];
  type: LessonType;
  status: LessonStatus;
  isConducted: boolean;
  deal: DealDto;
  teacher: TeacherDto;
  student: StudentDto;
  markedForFutureSubscription: boolean;
  history: HistoryDto<Partial<LessonDto>, LessonHistoryContext>[];
  subject: string | null;
  homework: string | null;
  notes: string | null;
  links: string[] | null;
  materials: MaterialDto[];
  clientRate: number | null;
  clientRateDetails: string[] | null;
  tags?: string[] | null;
  teacherRates: LessonTeacherRates[];
  homeworkCompletion: LessonHomeworkCompletion;
}

export interface DaysSchedule {
  day: number;
  time: string;
}

export interface CreateLessonDto {
  start: string;
  count: number;
  timeSlots: DaysSchedule[];
  type: 'first' | 'regular';
  status: 'booked' | 'reserved' | 'canceled';
  deal: string;
  teacherId: string;
  studentId: string;
  lang?: string;
  parentComment?: string;
}

export interface UpdateFirstLessonDto {
  lang?: string;
  parentComment?: string;
  teacherId?: string;
  date?: string;
  timeRange?: string;
}

export interface RateLessonDto {
  clientRate: number;
  clientRateDetails: string[];
}
